export default class Footer {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.App = document.body.querySelector('.App');

    this.init();
    //this.addEvents();

  }

  init() {

    if (window.DEVMODE) console.log('init Footer');

  }

  updateFooterSize() {

    this.DOM.App.style.marginBottom = `${this.DOM.el.offsetHeight}px`;

  }

  addEvents() {

    let resizeEvent = false;
    window.addEventListener('resize', () => {
      clearTimeout(resizeEvent);
      resizeEvent = setTimeout(this.updateFooterSize(), 500);
    });

  }

}
