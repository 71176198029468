import Highway from '@dogstudio/highway';

export default class DefaultPageRenderer extends Highway.Renderer {

  onEnter() {

    console.log('new pageee');
    this.DOM = { view: this.wrap.lastElementChild };
    document.title = "Les recettes Florelli";
    if (location.pathname !== "/"){
      document.title += ` - ${this.DOM.view.querySelector('h1').innerText}`;
    }
  }


}
