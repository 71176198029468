// Highway
import Highway from '@dogstudio/highway';
import DefaultPageRenderer from '../pages/page-renderer.js';
import DefaultPageTransition from '../pages/page-transition.js';

import pagePrllx from './prllx.js';
import pageInView from './inview.js';
import pageAnchors from './anchors.js';

export default class Routes {

  constructor(Header) {

    this.view = {};
    this.header = Header;
    this.navLinks = this.header.DOM.el.querySelectorAll('.Nav__link');

    this.createHighway();
    this.initView(document.body);

  }


  createHighway() {

    const H = new Highway.Core({
      renderers: {
        pagename: DefaultPageRenderer
      },
      transitions: {
        default: DefaultPageTransition
      }
    });

    H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_OUT');
      }

      // RemovePrllx
      this.view.prllx.destroy();

    });

    H.on('NAVIGATE_IN', ({ to, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_IN');
      }

      // Menu active links
      this.navLinks.forEach((link) => {
        link.classList.remove('is-active');
        if (link.href === location.href) {
          link.classList.add('is-active');
        }
      });

      this.initView(to.view);

    });

    H.on('NAVIGATE_END', ({ to, from, trigger, location }) => {

      if (window.DEVMODE) {
        console.log('NAVIGATE_END');
      }

      // Analytics
      /* if (typeof gtag !== 'undefined') {
         gtag('config', 'UA-XXXXXXXXX-X', {
           'page_path': location.pathname,
           'page_title': to.page.title,
           'page_location': location.href
         });
      } */

    });

  }

  initView(container) {

    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // Menu Toggle
    if (this.header.menuOpen) {
      const click = new Event('click');
      this.header.DOM.toggle.dispatchEvent(click);
    }

    // Check Anchors
    if (container.querySelector('.anchors__nav')) {
      this.view.anchors = new pageAnchors(container.querySelector('.anchors__nav'));
    }

    // Prllx / InView
    this.view.prllx = new pagePrllx(container);
    this.view.inview = new pageInView(container);

  }

}
