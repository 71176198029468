import { clamp, normalize } from '../utils.js';

export default class pagePrllx {

  constructor(container) {

    this.DOM = {
      container: container
    };

    this.DOM.prllxItems = this.DOM.container.querySelectorAll('*[data-prllx]');
    if (this.DOM.prllxItems.length === 0) return;

    this.pageScroll = 0;

    this.init();
    this.addEvent();

  }

  init() {

    if (window.DEVMODE) console.log('Init prllx');

    this.DOM.prllxItems.forEach((item) => {

      const prllxTL = new TimelineMax({ paused: true });
      if (!item.getAttribute('data-prllxSimple')) TweenMax.set(item, { y: -1 * item.getAttribute('data-prllx') });
      prllxTL.to(item, 1, { y: item.getAttribute('data-prllx'), overwrite: 'all', ease: Power0.easeNone });
      item.prllxTL = prllxTL;

    });

  }

  prllxRender() {

    this.DOM.prllxItems.forEach((item) => {
      let from = item.getBoundingClientRect().top + this.pageScroll - window.innerHeight;
      if (item.getAttribute('data-prllxSimple')) from = 0;
      const norm = clamp(normalize(this.pageScroll, from, item.getBoundingClientRect().top + this.pageScroll + item.offsetHeight), 0, 1);
      item.prllxTL.progress(norm);
    });

  }

  addEvent() {

    window.addEventListener('scroll', () => {
      this.pageScroll = window.pageYOffset || document.documentElement.scrollTop;
    });

    TweenMax.ticker.addEventListener('tick', this.prllxRender, this, true, 1);

  }

  destroy() {

    TweenMax.ticker.removeEventListener('tick', this.prllxRender);

  }

}
