export default class Header {

  constructor(el) {

    this.DOM = { el: el };
    this.DOM.body = document.body;
    //this.DOM.brand = this.DOM.el.querySelector('.brand');
    //this.DOM.toggle = this.DOM.el.querySelector('.Toggle__menu');
    //this.DOM.menuContainer = this.DOM.el.querySelector('Nav__container');
    this.menuOpen = false;

    this.init();

  }

  init() {

    if (window.DEVMODE) console.log('initHeader');

    // Toggle


  }

  open() {

    return new Promise((resolve, reject) => {

      // Console.log('Open Menu');
      this.menuOpen = true;
      this.DOM.body.classList.add('showMenu');
      this.DOM.body.style.overflow = 'hidden';

    });

  }

  close() {

    return new Promise((resolve, reject) => {

      // Console.log('Close Menu');
      this.menuOpen = false;
      this.DOM.body.classList.remove('showMenu');
      this.DOM.body.style.overflow = '';

    });

  }

}
